.editor-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  height: calc(var(--available-height, 1vh) * 100);
  width: 100vw;
  overflow: scroll;
  background: var(--tinylog-background-color-translucent);
  padding: 3.8em 0.4em;
}

.editor {
  font-size: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0.1em solid var(--tinylog-white);
  border-radius: 0.2em;
  color: var(--tinylog-white);
  background: var(--tinylog-background-color);
  position: relative;
}

.editor .button {
  background: none;
  border: none;
  outline: none;
  font-size: 0.8em;
  font-weight: bold;
  color: var(--tinylog-white);
  padding: 1.2em;
}

.editor .link {
  background: none;
  border: none;
  outline: none;
  font-weight: bold;
  color: var(--tinylog-white);
  padding: 0;
}

.editor label {
  display: inline-block;
  font-size: 0.8em;
  padding: 0.4em 0;
}

.editor .close {
  width: 100%;
  text-align: right;
}

.editor .close-button {
  border-left: 0.1em solid var(--tinylog-white);
  border-bottom: 0.1em solid var(--tinylog-white);
  padding: 0.6em 0.6em 0.2em 0.6em;
}

.editor .close-button img {
  width: 2em;
  height: 2em;
}

.editor .editor-form {
  padding: 0 5%;
  position: relative;
  width: 100%;
}

.editor .body {
  display: block;
  border: none;
  outline: none;
  padding: unset;
  background: transparent;
  color: var(--tinylog-white);
  font: normal 2em 'Barlow Semi Condensed';
  background-position-y: 1.01em;
  background-size: 6px 12px;
  background-repeat: repeat-x;
  width: 100%;
}

.device-ios .editor .body {
  background-position-y: 1.2em;
  padding-left: 0;
}

.editor.category-1 .body {
  background-image: url(/images/dot-asleep.svg);
}

.editor.category-2 .body {
  background-image: url(/images/dot-awake.svg);
}

.editor.category-3 .body {
  background-image: url(/images/dot-putdown.svg);
}

.editor.category-0 .body {
  background-image: url(/images/dot-note.svg);
}

.editor .dummy-body {  
  display: block;
  border: none;
  outline: none;
  background: transparent;
  color: transparent;
  font: normal 2em 'Barlow Semi Condensed';
  width: 90%;
  position: absolute;
  z-index: -1;
}

.editor .box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.6em;
}

.editor .box div {
  width: 48%;
}

.editor .box input, .editor .box textarea {
  border: 0.1em solid var(--tinylog-white);
  border-radius: 0.2em;
  background: transparent;
  font: bold 0.8em "DM Sans";
  color: var(--tinylog-white);
  text-align: center;
  vertical-align: middle;
  padding: 1em;
}

.editor .box input[type=date], .editor .box input[type=time] {
  display: block;
  padding-left: 2.8em;
  height: 3.4em;
}

/* iPhone 5 */
@media screen and (device-aspect-ratio: 40/71) {
  .editor .box input[type=date] {
    padding-left: 1.6em;
  }
}

.device-ios .editor .box input, .device-ios .editor .box textarea {
  width: 100%;
}

.editor.category-1 input:focus {
  outline-color: var(--tinylog-asleep-color);
}

.editor.category-2 input:focus {
  outline-color: var(--tinylog-awake-color);
}

.editor.category-3 input:focus {
  outline-color: var(--tinylog-putdown-color);
}

.editor.category-0 input:focus {
  outline-color: var(--tinylog-note-color);
}

.device-ios .editor.category-1 input:focus {
  width: 100%;
  border-color: var(--tinylog-asleep-color);
}

.device-ios .editor.category-2 input:focus {
  width: 100%;
  border-color: var(--tinylog-awake-color);
}

.device-ios .editor.category-3 input:focus {
  width: 100%;
  border-color: var(--tinylog-putdown-color);
}

.device-ios .editor.category-0 input:focus {
  width: 100%;
  border-color: var(--tinylog-note-color);
}

.editor .box input:disabled {
  background-color: var(--tinylog-bar-color);
  border: none;
}

.editor .date label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.8em;
  padding: 0.4em 0;
}

.editor .go-to-day {
  font-size: 1em;
  background-image: url(/images/arrow-right.svg);
  background-repeat: no-repeat;
  background-position: 98% 56%;
  background-size: 0.8em;
  padding-right: 1.4em;
}

.editor.category-1 .go-to-day {
  background-image: url(/images/arrow-right-asleep.svg);
  color: var(--tinylog-asleep-color);
}

.editor.category-2 .go-to-day {
  background-image: url(/images/arrow-right-awake.svg);
  color: var(--tinylog-awake-color);
}

.editor.category-3 .go-to-day {
  background-image: url(/images/arrow-right-putdown.svg);
  color: var(--tinylog-putdown-color);
}

.editor.category-0 .go-to-day {
  background-image: url(/images/arrow-right-note.svg);
  color: var(--tinylog-note-color);
}

.editor .category {
  margin-top: 0.6em;
  position: relative;
}

.editor .category select {
  width: 100%;
  padding: 1em;
  font: normal 0.8em "DM Sans";
  color: var(--tinylog-background-color);
  border-radius: 0.2em;
  -webkit-appearance: none;
}

.editor.category-1 select {
  outline-color: var(--tinylog-asleep-color);
}

.editor.category-2 select {
  outline-color: var(--tinylog-awake-color);
}

.editor.category-3 select {
  outline-color: var(--tinylog-putdown-color);
}

.editor.category-0 select {
  outline-color: var(--tinylog-note-color);
  color: var(--tinylog-white);
}

.editor .category img.dropdown-indicator {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 0.8em;
  right: 14px;
  transform: rotateZ(180deg);
}

.editor.category-1 .category select {
  background-color: var( --tinylog-asleep-color);
}

.editor.category-2 .category select {
  background-color: var( --tinylog-awake-color);
}

.editor.category-3 .category select {
  background-color: var( --tinylog-putdown-color);
}

.editor.category-0 .category select {
  background-color: var( --tinylog-note-color);
}

.editor .category.selected-1 select {
  background-color: var( --tinylog-asleep-color);
  color: var(--tinylog-background-color);
}

.editor .category.selected-2 select {
  background-color: var( --tinylog-awake-color);
  color: var(--tinylog-background-color);
}

.editor .category.selected-3 select {
  background-color: var( --tinylog-putdown-color);
  color: var(--tinylog-background-color);
}

.editor .category.selected-0 select {
  background-color: var( --tinylog-note-color);
  color: var(--tinylog-white-color);
}

.editor .delete {
  display: flex;
  justify-content: center;
  margin: 0.8em;
}

.editor .delete-button {
  background-image: url(/trashcan.svg);
  background-size: 1em;
  background-repeat: no-repeat;
  background-position: 0 50%;
  padding: 0.8em 0.8em 0.8em 1.6em;
}

.editor .footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 0.1em solid var(--tinylog-white);
}

.editor .footer button {
  width: 50%;
}

.editor .footer .cancel-button {
  border-right: 0.1em solid var(--tinylog-white);
}

.editor .footer .save-button {
  background: var(--tinylog-white);
  color: var(--tinylog-background-color);
}

.editor-container .shade {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--tinylog-background-color-translucent);
  display: none;
}

.editor .box-foot label {
  margin-bottom: -1.6em;
}

.editor .box-foot label {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}