.date-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.4em 0.2em 0 0.6em;
  background-color: var(--tinylog-background-color);
}

.date-header .display {
  height: 58px;
}

.date-header .date {
  font-weight: bold;
  padding: 0.4em 0;
}

.date-header .sleep-duration {
  display: flex;
  flex-direction: row;
  text-align: right;
  color: var(--tinylog-asleep-color);
}

.date-header .sleep-duration.off {
  visibility: hidden;
}

.date-header .sleep-duration .caption {
  padding-right: 0.2em;
}

.date-header .sleep-duration .duration {
  font-weight: bold;
}

.date-header button {
  background: none;
  border: none;
  outline: none;
}

.date-header img {
  width: 2.4em;
  height: 2.4em;
}

.toggle-view {
  font-size: 0.8em;
  color: var(--tinylog-white);
}