:root {
  --tinylog-asleep-color: #9DADFF;
  --tinylog-awake-color: #F7D252;
  --tinylog-putdown-color: #72E1E1;
  --tinylog-white: #FFFBEC;
  --tinylog-white-translucent: #FFFBECBF;
  --tinylog-background-color: #374F64;
  --tinylog-background-color-translucent: #374F64A0;
  --tinylog-bar-color: rgba(255, 251, 236, 0.1);
  --tinylog-red: #EC6F5E;
  --tinylog-note-color: #557998;
  --tinylog-scale-marks-color: rgba(255, 251, 236, 0.25);
  --tinylog-black: #26394a;
}

* {
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-family: 'DM Sans', sans-serif;
  background-color: var(--tinylog-background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading-animation {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100vh;
  height: calc(var(--available-height, 1vh) * 100);
  padding: 0 25%;
}