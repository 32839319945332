.entries {
  height: 100vh;
  height: calc(var(--available-height, 1vh) * 100);
  width: 100vw;
  overflow: scroll;
}

.App .loading-animation {
  width: 100%;
}

.entries ul {
  list-style: none;
}

.entries ul.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.entries ul.list li {
  font-size: 0.8em;
  border: 0.1em solid var(--tinylog-white-translucent);
  border-radius: 0.2em;
  position: relative;
  width: 96%;
  margin-top: 1em;
}

div.timestamp {
  padding: 0.6em 0.4em 0.2em 0.4em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div.timestamp .duration {
  font-weight: bold;
}

div.body {
  padding: 0 0.2em 0.2em 0.2em;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-size: 2em;
  display: flex;
  flex-direction: row; 
  align-content: flex-start;
}

div.body.note {
  padding-bottom: 0.32em;
}

div.bar {
  width: 100%;
  height: 1em;
  margin-top: 0.8%;
  background-color: var(--tinylog-bar-color);
  overflow: hidden;
}

div.bar div {
  height: 1em;
  position: relative;
}

div.bar .category-1 {
  background-color: var(--tinylog-asleep-color);
}

div.bar .category-2 {
  background-color: var(--tinylog-awake-color);
}

div.bar .category-3 {
  background-color: var(--tinylog-putdown-color);
}

div.bar img {
  display: flex;
  position: relative;
  height: 1em;
  width: 2em;
  top: -1em;
}

div.dogear-behind {
  position: absolute;
  top: -0.1em;
  right: -0.1em;
  border: 0.1em solid var(--tinylog-white);
  height: 20px;
  width: 20px;
  background-color: var(--tinylog-note-color);
}

div.dogear-top {
  position: absolute;
  height: 18px;
  width: 28px;
  background-color: var(--tinylog-background-color);
  transform: rotateZ(45deg);
  border-bottom: 0.1em solid var(--tinylog-white);
  top: -6px;
  right: -11px;
}

.entries .footer {
  padding-top: 1em;
  font-size: 0.8em;
  color: var(--tinylog-white-translucent);
  position: relative;
  width: 100%;
}

.entries .calendar + .footer {
  width: 200%;
}

.entries .footer.off {
  display: none;
}

.entries .footer-content {
  position: absolute;
  width: 50%;
  left: 0;
}

.entries .footer .blurb {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 2em 3em 2em;
}

.entries .footer img {
  width: 54px;
  height: 42px;
}

.entries .footer .message {
  width: 100%;
  background: var(--tinylog-bar-color);
}

.entries .footer .message button {
  margin: 0;
  padding: 0;
  color: var(--tinylog-white-translucent);
  text-decoration: underline;
  border: none;
  background: none;
  outline: none;
}

.entries .footer .message p {
  margin: 0;
  padding: 1em;
  text-align: center;
}

.entries .day {
  border: none;
  width: 100%;
  display: flex;
}

.entries .day .date {
  display: inline-block;
  text-align: center;
  width: 6%;
}

.entries .tape {
  display: inline-block;
  width: calc(100% / 28 * 24 + 2px);
}

.device-ios .entries .tape {
  width: calc(100% / 28 * 24 + 8px);
}

.entries div.summary {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  color: var(--tinylog-asleep-color);
  margin-left: 1em;
  font-weight: bold;
}

.device-ios .entries div.summary {
  width: unset;
}

.entries .day canvas {
  height: 4.4em;
  width: 100%;
  border: 1px solid var(--tinylog-white);
  border-radius: 0.2em;
  vertical-align: bottom;
}

.calendar {
  width: 200%;
  overflow: scroll;
  position: relative;
  display: flex;
  font-size: 0.8em;
  padding-top: 2.5px;
}

.calendar ul.days {
  width: 100%;
  margin: 0.8em 8px 0 8px;
}

.calendar .day-header-container {
  width: 4.2em;
  position: fixed;
  height: 100%;
  overflow: hidden;
  padding-left: 0.2em;
  pointer-events: none;
}

.calendar ul.day-header {
  position: absolute;
  width: 100%;
  padding-left: 1.2em;
  margin-top: 0.8em;
}

.calendar li {
  height: 56px;
  margin-bottom: 12px;
}

.calendar .day-header li {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.calendar .vertical-line-overlay-container {
  width: 100%;
  height: calc(100% + 2.8em);
  font-size: 1em;
  display: flex;
  pointer-events: none;
  position: absolute;
  margin-top: -2.8em;
  overflow: scroll;
}

.calendar table.vertical-line-overlay {
  height: 100%;
  margin: 0.8em 8px 0 8px;
  pointer-events: none;
  table-layout: fixed;
  max-width: none;
  width: auto;
  min-width: 100%;
}

.calendar table.vertical-line-overlay td {
  border-left: 1px solid var(--tinylog-scale-marks-color);
  color: transparent;
}

.calendar .now-marker-line {
  width: 1px;
  height: 100%;
  background-color: var(--tinylog-red);
  position: absolute;
  margin-top: 2.8em;
}

.calendar .now-marker-point {
  width: 6px;
  height: 6px;
  background-color: var(--tinylog-red);
  border-radius: 3px;
  position: absolute;
  margin-top: calc(2.8em - 3px);
}

.calendar th, .calendar td,
.time-header th, .time-header td {
  font-weight: normal;
  text-align: start;
  border: 1px solid transparent; /* HACK: to align time header with overlay lines */
}

.calendar table.vertical-line-overlay th {
  visibility: hidden; /* HACK: to align time header with overlay lines, we have to have the same structurs between two tables */
}

.time-header-container {
  overflow: scroll;
  width: 100%;
}

.time-header {
  height: 100%;
  pointer-events: none;
  table-layout: fixed;
  max-width: none;
  width: auto;
  min-width: 200%;
  margin: 0 8px;
  color: aliceblue;
}

.time-header th.summary span {
  color: var(--tinylog-asleep-color);
}

.time-header th span {
  font-size: 0.8em;
}