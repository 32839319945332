body, div, ul {
  margin: 0;
  padding: 0;
}

div {
  box-sizing: border-box;
}

input {
  box-sizing: border-box;
  width: 100%;
}

button {
  font: normal 1em "DM Sans", sans-serif;
  margin: 0;
}

input:focus {
  outline-color: var(--tinylog-asleep-color);
}

input:disabled {
  opacity: unset;
}

button.icon {
  font-size: 11px;
}

.App {
  background-color: var(--tinylog-background-color);
  color: var(--tinylog-white);
  min-height: 100vh;
  min-height: calc(var(--available-height, 1vh) * 100);
  width: 100vw;
  position: fixed;
  font-size: 1em;
}

.sub {
  background-color: var(--tinylog-background-color-translucent);
  min-height: 100vh;
  min-height: calc(var(--available-height, 1vh) * 100);
  position: absolute;
  top: 0;
  right: -100%;
  width: 100%;
  transition: right 600ms;
}

.sub.on {
  right: 0;
}

.toast {
  position: fixed;
  top: 2.2em;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0;
  transition: opacity 600ms ease-in;
  z-index: 999;
}

.toast p {
  background: var(--tinylog-putdown-color);
  color: var(--tinylog-background-color);
  padding: 0.4em;
  margin: 1em 2em;
  border-radius: 1em;
}