.settings-container {
  height: 100vh;
  height: calc(var(--available-height, 1vh) * 100);
  overflow-y: scroll;
  padding-bottom: 4em;
}
.settings {
  padding: 0.4em 2em;
  list-style: none;
}

.settings li {
  position: relative;
  padding: 1.4em 0;
  border-bottom: 0.08em solid var(--tinylog-white);
}

.settings h3 {
  margin-top: 0;
  font-size: 1em;
}

.settings select {
  -webkit-appearance: none;
  font: normal 0.8em "DM Sans";
  padding: 1em;
  background-color: var(--tinylog-background-color);
  color: var(--tinylog-white);
  width: 100%;
  border: 0.1em solid var(--tinylog-white-translucent);
  outline-color: var(--tinylog-asleep-color);
}

.settings select:focus {
  outline-color: var(--tinylog-asleep-color);
}

.device-ios .settings select:focus {
  border-color: var(--tinylog-asleep-color);
}

.settings img.dropdown-indicator {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4.6em;
  right: 14px;
  transform: rotateZ(180deg);
}

.settings a {
  color: var(--tinylog-white);
}

.settings button.location {
  display: inline;
  border: none;
  outline: none;
  padding: 0;
  background: none;
  text-align: start;
  text-decoration: underline;
  color: var(--tinylog-white);
  font-size: 1em;
  font-weight: bold;
}

.settings button.tips {
  display: block;
  border: none;
  outline: none;
  padding: 0.2em 0;
  background: none;
  background-image: url(/images/arrow-right.svg);
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 1.4em;
  width: 100%;
  text-align: start;
  color: var(--tinylog-white);
  font-size: 1em;
  font-weight: bold;
  height: unset;
}

.settings button.export {
  display: block;
  border: none;
  outline: none;
  padding: 0.2em 0;
  background: none;
  background-image: url(/export.svg);
  background-repeat: no-repeat;
  background-position: 100% 50%;
  width: 100%;
  text-align: start;
  color: var(--tinylog-white);
  font-size: 1em;
  font-weight: bold;
}

.settings p {
  font-size: 0.8em;
}

.settings .daytime {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.settings .daytime .start-time,
.settings .daytime .end-time {
  width: 48%;
}

.settings .daytime input {
  border: 0.1em solid var(--tinylog-white-translucent);
  border-radius: 0.2em;
  background: transparent;
  font: bold 0.8em "DM Sans";
  color: var(--tinylog-white);
  text-align: center;
  vertical-align: middle;
  padding: 0.8em;
}

.device-ios .settings .daytime input:focus {
  border-color: var(--tinylog-asleep-color);
}

.settings .daytime input:disabled {
  -webkit-appearance: none;
  background-color: var(--tinylog-bar-color);
  border-color: transparent;
}

.settings .daytime input[type=time] {
  display: block;
  padding-left: 2.8em;
}

.settings label {
  display: block;
  font-size: 0.88em;
  margin-bottom: 0.4em;
}
