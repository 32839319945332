.tips {
  height: 100vh;
  height: calc(var(--available-height, 1vh) * 100);
  overflow-y: scroll;
  padding-bottom: 4em;
  color: var(--tinylog-white);
}

.tips .back {
  background: none;
  outline: none;
  border: none;
  margin: 0.8em 0.8em 0.2em 0.8em;
  padding: 0;
}

.tips .back img {
  width: 1.2em;
  height: 1.2em;
  padding: 0;
}

.tips .content {
  padding: 0 2.2em;
}

.tips h2 {
  font-size: 1em;
  border-bottom: 0.1em solid var(--tinylog-white);
  padding: 1em 0;
  margin-top: 1em;
}

.tips h2.first {
  margin-top: 0;
  padding-top: 0;
}

.tips ul {
  list-style: none;
}

.tips li {
  margin-top: 2em;
}

.tips .basics li {
  border-bottom: 0.01em solid var(--tinylog-white);
}

.tips h3 {
  font-size: 1.4em;
  font-weight: normal;
  margin: 0;
}

.tips p {
  font-size: 0.8em;
}

.tips .box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1em;
}

.tips .balloon {
  display: inline-block;
  margin-right: 0.8em;
  margin-bottom: 1em;
  padding: 0.6em 0.8em;
  background: var(--tinylog-white);
  color: var(--tinylog-background-color);
  font-size: 0.8em;
  border-radius: 0.4em;
  border-bottom-right-radius: unset;
  position: relative;
  height: max-content;
  text-align: right;
}

.tips .balloon:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -0.5em;
  width: 0; 
  height: 0; 
  border-top: 0.8em solid transparent;
  border-bottom: 0.8em solid transparent; 
  border-right: 0.8em solid var(--tinylog-white);
  z-index: -1;
}

.tips a {
  color: var(--tinylog-white);
  font-weight: bold;
  text-decoration: underline;
}