.Textline {
  position: fixed;
  font-family: 'Barlow Semi Condensed', sans-serif;
  width: 100vw;
  height: 100vh;
  height: calc(var(--available-height, 1vh) * 100);
  overflow: scroll hidden;
}

.Textline .loading-animation {
  width: 100%;
}

.Textline button {
  background: none;
  outline: none;
  border: none;
  padding: 0;
  font-size: 1em;
}

.Textline .actions {
  position: relative;
  width: 100%;
  height: 100%;
}

.Textline .close-button {
  position: absolute;
  right: 0;
  background-color: var(--tinylog-background-color);
  border-left: 0.08em solid var(--tinylog-white-translucent);
  border-bottom: 0.08em solid var(--tinylog-white-translucent);
  padding: 0.4em 0.4em 0 0.4em;
}

.Textline .close-button img {
  width: 2em;
  height: 2em;
}

.Textline .send {
  position: fixed;
  right: 24px;
  bottom: 24px;
  height: 95px;
  width: 95px;
}

.Textline .help-button {
  position: fixed;
  left: 24px;
  bottom: 46px;
  height: 37px;
  width: 37px;
}

.Textline .notes {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
}

.Textline .message {
  box-sizing: border-box;
  position: absolute;
  display: inline-block;
  padding: 12px 8px;
  background-color: var(--tinylog-background-color);
  word-wrap: break-word;
  word-break: break-word;
  user-select: none;
}

.Textline .message.font-1 {
  font-size: 1.4em;
}

.Textline .message.font-2 {
  font-size: 1.6em;
}

.Textline .message.font-3 {
  font-size: 1.8em;
}

.Textline .message.font-4 {
  font-size: 2em;
}

.Textline .message.font-0 {
  font-size: 4em;
}

.Textline .message.color-0 {
  color: var(--tinylog-asleep-color);
  box-shadow: 4px 4px var(--tinylog-asleep-color);
  border: 2px solid var(--tinylog-asleep-color);
}

.Textline .message.color-1 {
  color: var(--tinylog-awake-color);
  box-shadow: 4px 4px var(--tinylog-awake-color);
  border: 2px solid var(--tinylog-awake-color);
}

.Textline .message.color-2 {
  color: var(--tinylog-putdown-color);
  box-shadow: 4px 4px var(--tinylog-putdown-color);
  border: 2px solid var(--tinylog-putdown-color);
}

.Textline .message.color-3 {
  color: var(--tinylog-white);
  box-shadow: 4px 4px var(--tinylog-white);
  border: 2px solid var(--tinylog-white);
}

.Textline .help {
  display: none;
  position: fixed;
  bottom: 24px;
  left: 24px;
  width: 172px;
  padding: 24px 12px;
  border: 3px solid var(--tinylog-red);
  box-shadow: 3px 3px var(--tinylog-red);
  background-color: var(--tinylog-background-color);
  color: var(--tinylog-red);
  font-size: 1.2em;
  text-align: start;
}

.Textline .help.on {
  display: block;
}

.Textline .help .close-button {
  background: none;
  border: none;
  outline: none;
  background-image: url(/images/close.svg);
  background-size: 36px 36px;
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  position: absolute;
  right: 8px;
  top: 8px;
}

.Textline .help p {
  width: 75%;
  margin: 0.4em 0;
  word-wrap: break-word;
}

.Textline .help p.send-now {
  font-weight: bold;
}