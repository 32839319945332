.modal-entry {
  position: absolute;
  top: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: top 200ms;
}

.modal-entry.on {
  top: 0;
}

.modal-entry .subnav {
  padding: 16px 8px 0 8px;
  background-color: var(--tinylog-background-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 2; /* In order for the "dogear-top" to sit behind this div. */
}

.modal-entry .subnav button {
  width: 2.4em;
  height: 2.4em;
  border: 1px solid var(--tinylog-white);
  border-radius: 2px;
  background: none;
  outline: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

.modal-entry .subnav button img {
  width: 1.6em;
  height: 1.6em;
}

.modal-entry ul {
  padding: 8px 8px 24px 8px;
  width: calc(100% - 16px);
  background-color: var(--tinylog-background-color);
  min-height: 6.2em;
}

.modal-entry li {
  border: 1px solid var(--tinylog-white);
  border-radius: 2px;
  position: relative;
}

div.dogear-behind {
  top: -1px;
  right: -1px;
  border: 1px solid var(--tinylog-white);
}

div.dogear-top {
  border-bottom: 1px solid var(--tinylog-white);
}