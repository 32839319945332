.homepage header {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 32px);
  height: 34px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 16px 16px 16px;
  border-bottom: 2px solid var(--tinylog-black);
  background-color: var(--tinylog-background-color);
  z-index: 1;
}

@media (min-width: 860px) {
  .homepage header {
    justify-content: space-around;
  }
}

.homepage .brick {
  height: 88px;
}

.homepage header img {
  display: block;
  height: 26px;
  margin-top: 4px;
}

.homepage header .spacer {
  width: 120px;
}

.country-US .homepage header .spacer,
.country-CA .homepage header .spacer {
  display: none;
}

.homepage .phone-number {
  text-decoration: none;
  color: var(--tinylog-white);
  border: 1px solid var(--tinylog-white);
  padding: 6px 8px;
  font-family: "Barlow Semi Condensed";
  display: none;
}

.homepage .phone-number.highlight {
  color: var(--tinylog-black);
  background-color: var(--tinylog-awake-color);
  border-color: var(--tinylog-background-color);
}

.country-US .homepage .phone-number.US {
  display: block;
}

.country-CA .homepage .phone-number.CA {
  display: block;
}

.homepage .content {
  width: 80%;
  margin: 0 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 860px) {
  .homepage .content {
    width: 30%;
    margin: 0 auto;
  }  
}

.homepage section {
  width: 100%;
}

.homepage .balloon {
  font-size: 22px;
}

@media (min-width: 860px) {
  .homepage .balloon {
    font-size: 1.4em;
  }
}

.homepage .balloon p {
  margin: 16px 0;
}

.homepage .balloon.large {
  display: inline-block;
  background-image: url(/images/speech-bubble-large.svg);
  width: 90%;
  max-width: 286px;
  background-repeat: no-repeat;
  background-size: auto;
  min-height: 164px;
  padding: 4px 24px 34px 24px;
  color: var(--tinylog-white);
  position: relative;
  margin-top: 88px;
}

.homepage .balloon.large .cat-snooze {
  background-image: url(/images/cat-snooze-2.svg);
  height: 87px;
  width: 123px;
  position: absolute;
  top: -62px;
  right: -10px;
}

.homepage .box.right {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 16px;
}

.homepage .balloon.button {
  display: inline-block;
  background-image: url(/images/speech-bubble-button.svg);
  width: 70%;
  max-width: 212px;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 112px;
  padding: 12px 24px;
  color: var(--tinylog-background-color);
}

.homepage .balloon.button button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  text-align: unset;
  padding: 0;
  color: var(--tinylog-background-color);
}

.homepage .balloon.button-teal {
  padding-top: 16px;
  background-image: url(/images/speech-bubble-button-teal.svg);
}

.homepage .balloon.button-teal button {
  text-align: unset;
  padding: 0;
  color: var(--tinylog-background-color);
}

.homepage .carousel {
  overflow-x: scroll;
  overflow-y: hidden;
  padding-left: 32px;
  background-image: linear-gradient(to bottom, var(--tinylog-background-color), var(--tinylog-black));
  width: 125%;
  margin-top: 32px;
}

@media (min-width: 860px) {
  .homepage .carousel {
    width: 100vw;
    overflow: unset;
  }
}

.homepage .carousel .container {
  width: 860px;
  height: 368px;
  margin: 0 auto;
}

.homepage .carousel .container img {
  width: 260px;
  margin-right: 24px;
}

.homepage .control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 16px 2px 16px;
  width: 112%;
}

@media (min-width: 860px) {
  .homepage .control {
    display: none;
  }
}

.homepage .control .caption,
.homepage div.captions ul.captions li.caption {
  font-size: 24px;
  font-family: "Barlow Semi Condensed";
  color: var(--tinylog-white);
}

.homepage .control .indicator {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 8px 0;
}

.homepage .control .indicator .dot {
  width: 8px;
  margin: 4px;
  background-color: var(--tinylog-black);
  height: 8px;
  border-radius: 4px;
}

.homepage .control .indicator .dot.white {
  background-color: var(--tinylog-white);
}

.homepage div.captions {
  display: none;
  width: 100vw;
  padding-left: 24px;
}

@media (min-width: 860px) {
  .homepage div.captions {
    display: unset;
  }
}

.homepage div.captions ul.captions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  padding: 24px 16px 8px 16px;
}

.homepage div.captions ul.captions li.caption {
  width: 260px;
  margin-right: 24px;
  text-align: center;
}

.homepage .help-link {
  width: 112%;
  padding: 0 0 32px 10px;
  visibility: hidden;
}

.homepage .help-link.on {
  visibility: visible;
}

@media (min-width: 860px) {
  .homepage .help-link {
    visibility: visible;
    width: 860px;
    text-align: right;
  }
}

.homepage .help-link button {
  background: none;
  outline: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  color: var(--tinylog-putdown-color);
}

.homepage .section3 .balloon {
  font-size: 18px;
}

@media (min-width: 860px) {
  .homepage .section3 .balloon {
    font-size: 1.36vw;
  }
}

.homepage .balloon.xlarge {
  background-image: url(/images/speech-bubble-xlarge.svg);
  margin-top: 64px;
  min-height: 24vw;
}

.homepage .balloon.xlarge .cat-peek {
  background-image: url(/images/cat-peek.svg);
  background-repeat: no-repeat;
  height: 59px;
  width: 70px;
  position: absolute;
  top: -44px;
  right: 16px;
}

.homepage .footer {
  font-size: 14px;
  color: #fffbec;
  background-color: var(--tinylog-black);
  padding: 48px 48px;
}

.homepage .footer a {
  color: #fffbec;
}