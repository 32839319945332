.homepage.help .tips {
  padding: 16px 32px 64px 32px;
  height: auto;
}

@media (min-width: 860px) {
  .homepage.help .tips {
    padding: 32px;
    width: 50%;
    margin: 0 auto;
  }
}

.homepage.help .tips .balloon {
  font-size: 0.8em;
}

.homepage.help .balloon.large {
  width: 78%;
}

.homepage.help .balloon.small {
  background-image: url(/images/speech-bubble-small.svg);
  width: unset;
  min-height: unset;
  padding: 8px 24px 30px 24px;
}


.homepage.help .balloon.small.logging-US,
.homepage.help .balloon.small.logging-CA {
  font-size: 18px;
  margin-bottom: 16px;
  margin-top: 8px;
  display: none;
}

.country-US .homepage.help .balloon.large.logging-CA {
  display: inline-block;
}

.country-CA .homepage.help .balloon.large.logging-US {
  display: inline-block;
}

.homepage.help .balloon.small.logging-US a,
.homepage.help .balloon.small.logging-CA a {
  color: var(--tinylog-white);
  text-decoration: none;
}