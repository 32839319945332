@keyframes bounce {
  from, to  {
    bottom: 5px;
    animation-timing-function: ease-out;
  }
  50% {
    bottom: 10px;
    animation-timing-function: ease-in;
  }
}

.banner {
  display: none;
}

.device-ios .banner {
  display: unset;
  position: absolute;
  bottom: 5px;
  width: 100%;

  animation-name: bounce;
  animation-iteration-count: infinite;
  animation-duration: 0.8s;
}

.device-ios.device-ipad .banner {
  display: none;
}

.banner p {
  font-size: 0.8em;
  width: 84%;
  margin: 0 auto;
  background-color: var(--tinylog-background-color);
  color: var(--tinylog-white);
  padding: 48px 16px;
  box-sizing: border-box;
  border: 1px solid var(--tinylog-white);
  border-radius: 2px;
  text-align: center;
}

.banner .pointer {
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  background-color: var(--tinylog-background-color);
  border-right: 1px solid var(--tinylog-white);
  border-bottom: 1px solid var(--tinylog-white);
  transform: rotateZ(45deg);
  margin-top: -12.8px;
}

.banner .close {
  box-sizing: border-box;
  border: none;
  outline: none;
  background: none;
  position: absolute;
  right: calc(8% + 1px);
  top: 0;
  width: 32px;
  height: 32px;
  border-left: 1px solid var(--tinylog-white);
  border-bottom: 1px solid var(--tinylog-white);
  padding: 0;
}

.banner .close img {
  width: 24px;
  height: 24px;
  padding: 4px;
}
