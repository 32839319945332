.filter {
  box-sizing: border-box;
  background-color: var(--tinylog-background-color);
  position: relative;
  width: 90%;
  height: 100vh;
  height: calc(var(--available-height, 1vh) * 100);
  margin-left: 10%;
  border-left: 0.08em solid var(--tinylog-white);
  transition: width 1s;
}

.filter .close-button {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  outline: none;
  border-left: 0.08em solid var(--tinylog-white-translucent);
  border-bottom: 0.08em solid var(--tinylog-white-translucent);
  padding: 0.4em 0.4em 0 0.4em;
}

.filter .close-button img {
  width: 2em;
  height: 2em;
}

.filter form {
  height: 100%;
  height: calc(var(--available-height, 1vh) * 100);
  padding: 2em 8%;
  color: var(--tinylog-white);
  overflow-y: scroll;
}

.filter h2 {
  font-size: 1em;
  font-weight: bold;
  margin: 0.6em 0 0 0;
}

.filter h3 {
  font-size: 1em;
  font-weight: normal;
  margin: 1.4em 0 -0.8em 0;
}

.filter hr {
  border: 0.04em solid var(--tinylog-white);
  margin: 1.6em 0 1.4em 0;
  padding: 0;
}

.box label.toggle {
  display: inline-block;
  margin-right: 0.8em;
  position: relative;
  height: 2em;
  line-height: 4.8em;
}

.box label.toggle input {
  display: none;
}

.box label.toggle span {
  border: 0.08em solid var(--tinylog-white);
  border-radius: 2em;
  padding: 0.6em 0.8em;
  font-size: 0.8em;
  font-weight: normal;
  line-height: 1;
}

.box label.toggle input:checked + span {
  background: var(--tinylog-white);
  color: var(--tinylog-background-color);
}

.box label.toggle input:checked + span.category-1 {
  background: var(--tinylog-asleep-color);
  color: var(--tinylog-background-color);
}

.box label.toggle input:checked + span.category-2 {
  background: var(--tinylog-awake-color);
  color: var(--tinylog-background-color);
}

.box label.toggle input:checked + span.category-3 {
  background: var(--tinylog-putdown-color);
  color: var(--tinylog-background-color);
}

.box label.toggle input:checked + span.category-0 {
  background: var(--tinylog-note-color);
  color: var(--tinylog-white-color);
}

.box .custom-time {
  margin-top: 1.8em;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.box .custom-time .start-time,
.box .custom-time .end-time {
  width: 46%;
}

.box .custom-time .separator {
  padding: 0.6em;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.box .custom-time input {
  border: 0.1em solid var(--tinylog-white);
  border-radius: 0.2em;
  background: transparent;
  font: bold 0.8em "DM Sans";
  color: var(--tinylog-white);
  text-align: center;
  vertical-align: middle;
  padding: 1em 0;
}

.box .custom-time.off {
  display: none;
}

.box .custom-time input[type=time] {
  padding-left: 0.8em;
}

.box .custom-time input:focus {
  outline-color: var(--tinylog-asleep-color);
}

.device-ios .box .custom-time input:focus {
  border-color: var(--tinylog-asleep-color);
}

.box div.sort-option {
  margin: 0.8em 0;
}

.box label.circle input {
  display: none;
}

.box label.circle + label {
  padding: 0 0.6em;
  vertical-align: middle;
}

.box label.circle span.image {
  display: inline-block;
  background-image: url("/circle_off.svg");
  width: 1.6em;
  height: 1.6em;
  vertical-align: middle;
}

.box label.circle input:checked + span.image {
  background-image: url("/circle_on.svg");
}

.filter .footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0; 
}

.filter .footer.pristine {
  display: none;
}

.filter .footer button {
  background: none;
  border: none;
  outline: none;
  font-size: 1em;
  font-weight: bold;
  padding: 1.2em;
}

.filter .footer .clear-button {
  color: var(--tinylog-white);
  border-right: 0.1em solid var(--tinylog-white);
  background-image: url(/cross.svg);
  background-repeat: no-repeat;
  background-position: 15.8em 52%;
  background-size: 1.4em;
}

.filter .footer .apply-button {
  background: var(--tinylog-white);
  color: var(--tinylog-background-color);
}