span.filter-indicator {
  display: inline-block;
  border: 0.08em solid var(--tinylog-white);
  border-radius: 2em;
  padding: 0.6em 0.8em;
  font-size: 0.8em;
  font-weight: normal;
  line-height: 1;
  margin: 0.5em 0.8em 0.5em 0;
}

span.filter-indicator.category-1 {
  background: var(--tinylog-asleep-color);
  color: var(--tinylog-background-color);
}

span.filter-indicator.category-2 {
  background: var(--tinylog-awake-color);
  color: var(--tinylog-background-color);
}

span.filter-indicator.category-3 {
  background: var(--tinylog-putdown-color);
  color: var(--tinylog-background-color);
}

span.filter-indicator.category-0 {
  background: var(--tinylog-note-color);
  color: var(--tinylog-white-color);
}

.display.filter-header {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.display.filter-header > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.display.filter-header .clear {
  color: var(--tinylog-white);
  background-image: url(/cross.svg);
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 1.8em;
  padding-right: 2em;
  padding-left: 0;
  font-weight: bold;
  font-size: 0.8em;
}
