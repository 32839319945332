.nav {
  height: 6vmax;
  border-bottom: 0.1em solid var(--tinylog-white-translucent);
  width: 98%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1% 1%;
  background-color: var(--tinylog-background-color);
}

.nav .left, .nav .right {
  width: 96px;
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
}

.nav button {
  background: none;
  border: none;
  outline: none;
}

.nav a {
  display: flex;
  justify-content: center;
  width: 2.4em;
}

.nav button img {
  width: 2.4em;
  height: 2.4em;
}

.nav button div {
  display: none;
}

.nav button.new div {
  display: unset;
  position: relative;
}

.nav button.new div span {
  position: absolute;
  top: -2.4em;
  right: -2em;
  display: block;
  background-color: var(--tinylog-red);
  color: var(--tinylog-white);
  padding: 2px 4px;}
